import {
  BaseStepIdEnum,
  StepConfig,
  StepTypeEnum,
  FieldTypeEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  // {
  //   id: BaseStepIdEnum.company_search,
  // },
  // {
  //   id: BaseStepIdEnum.company_list,
  // },
  {
    id: 'business_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        // {
        //   id: 'business_type',
        //   type: FieldTypeEnum.select,
        //   options: ['company', 'association'],
        //   isRequired: true,
        //   isEnabled: true,
        //   entityType: FieldEntityTypeEnum.case,
        //   propertyType: FieldPropertyTypeEnum.custom,
        // },
        {
          id: 'predicted_monthly_spend',
          type: FieldTypeEnum.select,
          options: [
            'between_0_and_10k',
            'between_10_and_50k',
            'between_50_and_200k',
            'between_200_and_500k',
            'more_than_500k',
          ],
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'spend_purposes',
          type: FieldTypeEnum.checkbox,
          options: [
            'business_meals',
            'office_supplies',
            'advertising_and_marketing',
            'software_expenses',
            'utility_bills',
            'employee_perks',
            'travel_expenses',
            'professional_fees_and_business_services',
            'others',
          ],
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'other_spend_purproses',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          hasPlaceholder: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'number_of_spendesk_users',
          type: FieldTypeEnum.select,
          options: [
            'between_1_and_19',
            'between_20_and_49',
            'between_50_and_249',
            'more_than_250',
          ],
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'company_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address_2',
          nested: 'address',
          hasPlaceholder: true,
          isRequired: false,
          isEnabled: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'city',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'state',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country_of_fiscal_residence',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'alternative_activity_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'alternative_address_line_1',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'alternative_address_line_2',
          hasPlaceholder: true,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'alternative_postal_code',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'alternative_city_address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'alternative_state_address',
          type: FieldTypeEnum.text,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'alternative_country_address',
          type: FieldTypeEnum.country,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  // {
  //   key: BaseStepEnum.affiliated_companies_list,
  // },
  {
    id: 'source_of_funds',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'source_of_funds',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          options: [
            'retained_earnings',
            'activity_incomes',
            'sale_of_assets',
            'capital_injection',
            'dividends',
            'investment_funds',
            'venture_capital_funding',
            'private_investors',
            'grants',
            'bank_loan',
            'crowdfunding',
            'donations',
            'ipo',
            'others',
          ],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'other_source_of_funds',
          type: FieldTypeEnum.text,
          hasPlaceholder: true,
          isRequired: false,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true, hasSignatory: true },
  },
  {
    id: 'applicants_phone_number',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'applicants_phone_number',
          type: FieldTypeEnum.tel,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'has_beneficial_ownership_declaration',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'has_beneficial_ownership_declaration',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          options: ['true', 'false'],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'final_confirmation',
    type: StepTypeEnum.text,
  },
];
